const API_URL = "https://api.thejustbook.com/survey";
const axios = require('axios');

export function revertScanRender() {
  return {
    type: 'REVERT_SCAN_RENDER'
  }
}

export function isLoading(isLoading) {
  return {
    type: 'IS_LOADING',
    isLoading: isLoading
  }
}

export function formSubmitted(response) {
  return {
    type: 'FORM_SUBMIT',
    formSubmitted: response
  }
}

export function formSubmitFail(response) {
  return {
    type: 'FORM_SUBMIT_FAIL',
    formSubmitFailed: response
  }
}

export function submitForm(formData) {
  return async (dispatch) => {
    dispatch(isLoading(true));
    let response;
    try {
      response = await axios.post(API_URL, formData, {
        headers: { 
          'Content-Type': 'application/json'
        }
      })
      dispatch(formSubmitted(response));
    } catch (e) {
      dispatch(formSubmitFail(true));
      dispatch(isLoading(false));
    }
    dispatch(isLoading(false));
  }
}

export function resetForm() {
  return {
    type: 'RESET_FORM'
  }
}