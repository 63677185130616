import { combineReducers } from 'redux';
import { scanner } from './scanner-reducer';
import { form } from './form-reducer';

const appReducer = combineReducers({
    scanner,
    form
})

const rootReducer = (state, action) => {

    // RESET Store after FORM Submit
    if (action.type === 'RESET_FORM') {
        state = {};
    }    

    return appReducer(state, action);
}

export default rootReducer;
