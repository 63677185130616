export function scanSuccess(qrData) {
  return {
    type: 'SCAN_DATA',
    qrData: qrData
  }
}

export function scanFailure(failedData) {
  return {
    type: 'SCAN_FAIL_DATA',
    qrData: failedData
  }
}

export function revertScanRender() {
  return {
    type: 'SCAN_RENDER_REVERT'
  }
}