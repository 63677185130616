import React from 'react';
import styles from './style.module.scss';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { scanSuccess, scanFailure } from '../../redux/actions/scanner-action';
import { BrowserMultiFormatReader } from '@zxing/library';

class Scanner extends React.Component {

  async componentWillMount() {
    // Scanning QR
    const codeReader = new BrowserMultiFormatReader();
    try {
      let result = await codeReader.decodeOnceFromVideoDevice(undefined, 'scannerVideo');
      this.props.scanQR(result.text);
    } catch (err) {
      this.props.scanFailure(err);
    }
  }

  render() {
    if (this.props.scanPageRender) {
      return <Redirect to='/Form'/>;
    }
    return (
      <div className="mainContainer">
        <p className={styles.heading}>Fashions Feedback Systems</p>
        <div className={styles.scannerWrapper}>
          <p className={styles.heading}>Scan QR code</p>
          <div className={styles.video}>
            <video
              id="scannerVideo"
              width="300"
              height="200"
            ></video>
          </div>
        </div>
      </div>
    );
  }

}

Scanner.defaultProps = {
  qrData: null,
  scanPageRender: false
}

Scanner.propTypes = {
  scanQR: PropTypes.func,
  scanFailure: PropTypes.func,
  scanPageRender: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    qrData: state.scanner.qrData,
    scanPageRender: state.scanner.scanPageRender,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    scanQR: (qrSuccessData) => dispatch(scanSuccess(qrSuccessData)),
    scanFailure: (qrFailData) => dispatch(scanFailure(qrFailData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scanner);
