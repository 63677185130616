import React from 'react';
import styles from './style.module.scss';
import InputRange from 'react-input-range';
import { Container, Row, Col } from 'react-bootstrap';
import {
  Link
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import { submitForm, resetForm } from '../../redux/actions/form-action';
import { revertScanRender } from '../../redux/actions/scanner-action';
import HashLoader from "react-spinners/HashLoader";
import { Modal } from 'react-bootstrap';
const _loadsh = require('lodash');

class Form extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      qrData: '',
      gender: 'M',
      selectedProduct: 'shirt',
      additionalFeedback: '',
      dressSize: '',
      brand: '',
      shopName: '',
      feedBack: {
        collar: 0,
        sleeveLength: 0,
        chest: 0,
        waist: 0,
      },
      activePhoto: 'collar',
      localtionLat: 0,
      locationLongitude: 0
    };
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
  }

  // Submit Form validation & action
  submitForm = () => {
    if (this.validator.allValid()) {
      let formData = {
        qrData: {
          qrNumber: this.props.qrData
        },
        dressModel: this.state.selectedProduct,
        location: {
          lat: this.state.localtionLat,
          lang: this.state.locationLongitude
        },
        brand: this.state.brand,
        shopName: this.state.shopName,
        dressSize: Number(this.state.dressSize),
        feedBack: this.getFeedBack(this.state.feedBack, this.state.selectedProduct)
      };
      this.props.submitForm(formData);
    } else {
      this.validator.showMessages();
    }
  }

  // Getting Location
  getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) { 
      location.getCurrentPosition((position) => {
        if (this._isMounted) {
          this.setState({
            localtionLat: position.coords.latitude,
            locationLongitude: position.coords.longitude
          });          
        }
      });
    }
  }

  // FeedBack Data 
  getFeedBack(feedBack, selectedProduct) {
    if ( _loadsh.isEmpty(feedBack) && selectedProduct === 'shirt' ) {
      feedBack['collar'] = 0;
      feedBack['sleeveLength'] = 0;
      feedBack['chest'] = 0;
      feedBack['waist'] = 0;
      return feedBack;
    } else if ( _loadsh.isEmpty(feedBack) && selectedProduct === 'pant' ) {
      feedBack['waistBand'] = 0;
      feedBack['seat'] = 0;
      feedBack['thigh'] = 0;
      feedBack['cuff'] = 0;
      feedBack['lengthOutside'] = 0;
      return feedBack;
    } else {
      return this.state.feedBack
    }
  }

  /* Gender & Size */
  _category() {
    return (
      <Row>
        <Col lg="6" xs="12">
          <div className={`${styles.fieldWrapper} ${styles.radioWrapper}`}>
            <label className={styles.label}>Select Category <span className={styles.asterisk}>*</span></label>
            <label className={styles.inputRadio}>
              <input 
                name="product"
                type="radio"
                value="shirt"
                onChange={e => this.setState({
                  selectedProduct: e.target.value,
                  activePhoto: 'collar',
                  feedBack: {}
                })}
                checked={ this.state.selectedProduct === 'shirt' }
              />
              <span>Shirt</span>
            </label>
            <label className={styles.inputRadio}>
              <input 
                name="product"
                type="radio"
                value="pant"
                onChange={e => this.setState({ 
                  selectedProduct: e.target.value,
                  activePhoto: 'waistband',
                  feedBack: {}
                })}
                checked={this.state.selectedProduct === 'pant'}
              />
              <span>Pant</span>
            </label>
          </div>
        </Col>
        <Col lg="6" xs="12">
          <div className={styles.fieldWrapper}>
            <label className={styles.label}>Size <span className={styles.asterisk}>*</span></label>
            <input
              name="size"
              type="type"
              className={styles.inputBox}
              value={this.state.dressSize}
              onChange={e => this.setState({ dressSize: e.target.value })}
              maxLength={2}
              placeholder="Size"
              onBlur={() => this.validator.showMessageFor('size')}
            />
            <div className={styles.errorMsg}>
              {this.validator.message('size', this.state.dressSize, 'required|numeric|size:2')}
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  /* Product View Change */
  _productView() {
    if (this.state.selectedProduct === 'shirt') {
      return (
        <Container>
          {this._category()}
          <Row>
            <Col lg="6" xs="12">
              <div className={styles.fieldWrapper}>
                <label className={styles.label}>Brand Name <span className={styles.asterisk}>*</span></label>
                <input
                  name="brandName"
                  type="text"
                  className={styles.inputBox}
                  value={this.state.brand}
                  onChange={e => this.setState({ brand: e.target.value })}
                  placeholder="Brand Name"
                />
                <div className={styles.errorMsg}>
                  {this.validator.message('brandName', this.state.brand, 'required')}
                </div>
              </div>
            </Col>
            <Col lg="6" xs="12">
              <div className={styles.fieldWrapper}>
                <label className={styles.label}>Shop Name <span className={styles.asterisk}>*</span></label>
                <input
                  name="shopName"
                  type="text"
                  className={styles.inputBox}
                  value={this.state.shopName}
                  onChange={e => this.setState({ shopName: e.target.value })}
                  placeholder="Shop Name"
                />
                <div className={styles.errorMsg}>
                  {this.validator.message('shopName', this.state.shopName, 'required')}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xs="12">
              <div className={styles.fieldWrapper}>
                <div className={styles.sliderWrapper}>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Collar Size</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.collar ? this.state.feedBack.collar : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.collar = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'collar'
                        })
                      }} 
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Sleeve Length</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.sleeveLength ? this.state.feedBack.sleeveLength : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.sleeveLength = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'sleeveLength'
                        })
                      }} 
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Chest Size</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.chest ? this.state.feedBack.chest : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.chest = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'chest'
                        })
                      }}  
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Waist Size</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.waist ? this.state.feedBack.waist : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.waist = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'waist'
                        })
                      }} 
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6" xs="12">
              {/* <div className={styles.photoView}>
                <div className={styles.fieldWrapper}>
                  <label className={styles.label}>Shirt View</label>
                  <div className={styles.imgBox}>
                    <img src={`${process.env.PUBLIC_URL}/img/shirt/${this.state.activePhoto}.png`} alt="activePhoto" />
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container>
          {this._category()}
          <Row>
            <Col lg="6" xs="12">
              <div className={styles.fieldWrapper}>
                <label className={styles.label}>Brand Name <span className={styles.asterisk}>*</span></label>
                <input
                  name="brandName"
                  type="text"
                  className={styles.inputBox}
                  value={this.state.brand}
                  onChange={e => this.setState({ brand: e.target.value })}
                  placeholder="Brand Name"
                />
                <div className={styles.errorMsg}>
                  {this.validator.message('brandName', this.state.brand, 'required')}
                </div>
              </div>
            </Col>
            <Col lg="6" xs="12">
              <div className={styles.fieldWrapper}>
                <label className={styles.label}>Shop Name <span className={styles.asterisk}>*</span></label>
                <input
                  name="shopName"
                  type="text"
                  className={styles.inputBox}
                  value={this.state.shopName}
                  onChange={e => this.setState({ shopName: e.target.value })}
                  placeholder="Shop Name"
                />
                <div className={styles.errorMsg}>
                  {this.validator.message('shopName', this.state.shopName, 'required')}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xs="12">
              <div className={styles.fieldWrapper}>
                <div className={styles.sliderWrapper}>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Waistband Size</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.waistBand ? this.state.feedBack.waistBand : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.waistBand = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'waistBand'
                        })
                      }} 
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Seat Size</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.seat ? this.state.feedBack.seat : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.seat= value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'seat'
                        })
                      }} 
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Thigh Size</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.thigh ? this.state.feedBack.thigh : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.thigh = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'thigh'
                        })
                      }}  
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Cuff Size</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.cuff ? this.state.feedBack.cuff : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.cuff = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'cuff'
                        })
                      }} 
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>length</label>
                    <InputRange
                      maxValue={10}
                      minValue={-10}
                      value={this.state.feedBack.lengthOutside ? this.state.feedBack.lengthOutside : 0}
                      onChange={(value) => {
                        let feedBack = {...this.state.feedBack}
                        feedBack.lengthOutside = value;
                        this.setState({ 
                          feedBack,
                          activePhoto: 'lengthOutside'
                        })
                      }} 
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6" xs="12">
            <div className={styles.photoView}>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}>Pant View</label>
                <div className={styles.imgBox}>
                  <img src={`${process.env.PUBLIC_URL}/img/pant/${this.state.activePhoto}.png`} alt="activePhoto" />
                </div>
              </div>
            </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.revertScanRender();
    this.getMyLocation();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.resetForm();
  }

  render() {

    // Loading Indicator
    if (this.props.isLoading) {
      return (
        <div className={styles.loaderWrapper}>
          <HashLoader
            size={50}
            margin={2}
            color={"#fff"}
            loading={this.props.isLoading}
          />
        </div>
      )
    }

    // Form Submit Modal Popup
    if (this.props.formSubmitted) {
      return (
        <div className={styles.successModal}>
          <Modal show={this.props.formSubmitted} centered>
            <Modal.Body>Thanks for the feedback on your experience</Modal.Body>
            <Modal.Footer>
              <Link to="/" className="btn btn-primary">Ok</Link>
            </Modal.Footer>
          </Modal>
        </div>
      )
    }

    // Form Submit Fail Modal Popup
    if (this.props.formSubmitFailed) {
      return (
        <div className={styles.successModal}>
          <Modal show={this.props.formSubmitFailed} centered>
            <Modal.Body>Oops, there was a problem with the network. Please Try again...!</Modal.Body>
            <Modal.Footer>
              <Link to="/" className="btn btn-danger">Ok</Link>
            </Modal.Footer>
          </Modal>
        </div>
      )
    }

    // If QrData is empty redirect to QR SCAN page
    if ( _loadsh.isEmpty(this.props.qrData) ) {
      // return <Redirect to='/'/>;
    }

    return (
      <div className="mainContainer">
        <Container>
          <Row>
            <div className={styles.formContainer}>
              <div className={styles.formHeader}>
                <Link to="/" className={styles.backBtn}>
                  
                </Link>
                <div className={styles.formHeading}>Fashions Feedback Systems</div>
              </div>
              <Row>
                <Col lg="6" xs="12">
                  <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Bar Code <span className={styles.asterisk}>*</span></label>
                    <input 
                      name="qrCode"
                      type="text"
                      placeholder="Bar Code"
                      className={styles.inputBox}
                      value={this.props.qrData ? this.props.qrData : ''}
                      readOnly={true}
                    />
                    <div className={styles.errorMsg}>
                      {this.validator.message('qrCode', this.state.dressSize, 'required')}
                    </div>
                  </div>
                </Col>
                <Col lg="6" xs="12">
                  <div className={`${styles.fieldWrapper} ${styles.radioWrapper}`}>
                    <label className={styles.label}>Gender <span className={styles.asterisk}>*</span></label>
                    <label className={styles.inputRadio}>
                      <input 
                        name="gender"
                        type="radio"
                        value="M"
                        onChange={e => this.setState({
                          gender: e.target.value
                        })}
                        checked={ this.state.gender === 'M' }
                      />
                      <span>Male</span>
                    </label>
                    <label className={styles.inputRadio}>
                      <input 
                        name="gender"
                        type="radio"
                        value="F"
                        onChange={e => this.setState({ 
                          gender: e.target.value
                        })}
                        checked={this.state.gender === 'F'}
                      />
                      <span>Female</span>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                {this._productView()}
              </Row>
              <div className={styles.fieldWrapper}>
                <label className={styles.label}>Additional Feedbacks</label>
                <textarea
                  name="additionalFeedback"
                  placeholder="Additional Feedbacks"
                  className={styles.inputTextArea}
                  value={this.state.additionalFeedback}
                  onChange={e => this.setState({ additionalFeedback: e.target.value })}
                />
              </div>
              <button className={styles.submitBtn} onClick={this.submitForm}>Submit</button>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
  

}

const mapStateToProps = (state) => {
  return {
    qrData: state.scanner.qrData ? state.scanner.qrData : "null",
    isLoading: state.form.isLoading,
    formSubmitted: state.form.formSubmitted,
    formSubmitFailed: state.form.formSubmitFailed
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    revertScanRender: () => dispatch(revertScanRender()),
    submitForm: (formData) => dispatch(submitForm(formData)),
    resetForm: () => dispatch(resetForm())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
