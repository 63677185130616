export function scanner(state = {}, action) {
  switch (action.type) {
    case 'SCAN_DATA':
      return {
        ...state,
        qrData: action.qrData,
        scanPageRender: true
      };
    case 'SCAN_RENDER_REVERT':
      return {
        ...state,
        scanPageRender: false
      }
    case 'SCAN_FAIL_DATA':
      return {
        ...state,
        qrData: action.qrData
      }
    default:
        return state;
  }
}
