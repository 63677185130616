export function form(state = {}, action) {
  switch (action.type) {
    case 'FORM_SUBMIT':
      return {
        ...state,
        formSubmitted: action.formSubmitted
      };
    case 'FORM_SUBMIT_FAIL':
      return {
        ...state,
        formSubmitFailed: action.formSubmitFailed
      };
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      };
    default:
        return state;
  }
}