import React from 'react';
import { Route, BrowserRouter } from "react-router-dom";
import Scanner from './containers/Scanner';
import Form from './containers/Form';

const App = () => (
  <BrowserRouter>
    <div className="pageContainer">
      <Route exact path="/" component={Scanner} />
      <Route exact path="/Form" component={Form} />
    </div>
  </BrowserRouter>
);

export default App;
